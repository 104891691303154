<template>
  <div class="p-5 font-size-30 text-center">
    <div class="font-weight-bold mb-3">Halaman tidak ditemukan</div>
    <div>Halaman ini bisa jadi sudah usang, dihapus, atau memang tidak ada</div>
    <div class="font-weight-bold font-size-70 mb-1">404 —</div>
    <router-link to="/" class="btn btn-outline-primary width-100">
      Kembali
    </router-link>
  </div>
</template>
